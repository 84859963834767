import FolderIcon from '@material-ui/icons/Folder'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import {
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
} from '@material-ui/core'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DOCUMENT_REGISTRATION,
  DOCUMENT_PORTAL,
  DOCUMENT_SETUP,
  HELP_REQUEST,
  KNOWLEDGE_BASE,
  CERTIFICATION_EVENTS,
  DASHBOARD,
  DOCUMENT_REPOSITORY,
  THIRD_PARTY_REPOSITORY,
  DIGITAL_SELLER_SURVEY,
  CERTIFICATION_MANAGER,
} from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import ForumIcon from '@material-ui/icons/Forum'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import {
  Assignment,
  AssignmentInd,
  ChromeReaderMode,
  Dashboard,
  Notes,
  Receipt,
} from '@material-ui/icons'
import { ListItemNested, ListItemStyle, ListSubMenu } from './styles'

interface MenuProps {
  onCloseDrawer: () => void
  opendedMenu?: boolean
  menuExpanded: string | boolean
  handleMenuChange: (item: any, isExpanded: boolean) => void
}

const Menus = ({
  onCloseDrawer,
  opendedMenu,
  menuExpanded,
  handleMenuChange,
}: MenuProps) => {
  const { userPermissions } = useContext(AuthContext)

  const history = useHistory()

  const getSelectedMenu = (locationUrl: string) => {
    switch (locationUrl) {
      case DASHBOARD:
        return 0
      case DOCUMENT_REGISTRATION:
        return 1
      case DOCUMENT_SETUP:
        return 2
      case DIGITAL_SELLER_SURVEY:
        return 3
      case CERTIFICATION_EVENTS:
        return 4
      case DOCUMENT_PORTAL:
        return 5
      case DOCUMENT_REPOSITORY:
        return 7
      case THIRD_PARTY_REPOSITORY:
        return 8
      case CERTIFICATION_MANAGER:
        return 9
      default:
        return 999
    }
  }

  const location = useLocation()
  const [selectedIndex, setSelectedIndex] = React.useState(
    getSelectedMenu(location.pathname)
  )
  const [openRepoSubMenu, setOpenRepoSubMenu] = React.useState(
    !![DOCUMENT_REPOSITORY, THIRD_PARTY_REPOSITORY].includes(location.pathname)
  )
  const ability = useContext(AbilityContext)
  const handleSelectItem = useCallback((index: number): void => {
    setSelectedIndex(index)
  }, [])
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const handleSubMenuClick = () => {
    setOpenRepoSubMenu(!openRepoSubMenu)
  }

  useEffect(() => {
    setSelectedIndex(getSelectedMenu(location.pathname))
  }, [location])

  return (
    <List>
      {userPermissions.type === 'internal' && (
        <ListItemStyle
          button
          selected={selectedIndex === 0}
          onClick={(): void => {
            onCloseDrawer()
            handleSelectItem(0)
            handleMenuChange('dashboard', menuExpanded === 'dashboard')
            setOpenRepoSubMenu(false)
            history.push(DASHBOARD)
          }}
        >
          <ListItemIcon>
            <Tooltip title="UDD Dashboard">
              <Dashboard fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="UDD Dashboard" />
        </ListItemStyle>
      )}

      <ListItemStyle
        button
        selected={selectedIndex === 1}
        onClick={(): void => {
          onCloseDrawer()
          handleSelectItem(1)
          handleMenuChange('docReg', menuExpanded === 'docReg')
          setOpenRepoSubMenu(false)
          history.push(DOCUMENT_REGISTRATION)
        }}
      >
        <ListItemIcon>
          <Tooltip title="Document Registration">
            <FolderIcon fontSize="small" />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Document Registration" />
      </ListItemStyle>

      <ListItemStyle
        button
        selected={selectedIndex === 3}
        onClick={(): void => {
          onCloseDrawer()
          handleSelectItem(3)
          handleMenuChange('surSetup', menuExpanded === 'surSetup')
          setOpenRepoSubMenu(false)
          history.push(DIGITAL_SELLER_SURVEY)
        }}
      >
        <ListItemIcon>
          <Tooltip title="Survey Setup">
            <Notes fontSize="small" />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Survey Setup" />
      </ListItemStyle>

      {userPermissions.type === 'internal' && (
        <Can
          do={PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal}
          on="any"
        >
          {(ability.can(
            PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
            'any'
          ) ||
            ability.can(
              PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
              'any'
            )) && (
            <ListItemStyle
              button
              selected={selectedIndex === 2}
              onClick={(): void => {
                onCloseDrawer()
                handleSelectItem(2)
                handleMenuChange('docSetup', menuExpanded === 'docSetup')
                setOpenRepoSubMenu(false)
                history.push(DOCUMENT_SETUP)
              }}
            >
              <ListItemIcon>
                <Tooltip title="Document Setup">
                  <NoteAddIcon fontSize="small" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Document Setup" />
            </ListItemStyle>
          )}
          <ListItemStyle
            button
            selected={selectedIndex === 4}
            onClick={(): void => {
              onCloseDrawer()
              handleSelectItem(4)
              handleMenuChange(
                'certificationEvent',
                menuExpanded === 'certificationEvent'
              )
              setOpenRepoSubMenu(false)
              history.push(CERTIFICATION_EVENTS)
            }}
          >
            <ListItemIcon>
              <Tooltip title="Certification Events">
                <Receipt fontSize="small" />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Certification Events" />
          </ListItemStyle>
          <ListItemStyle
            button
            selected={selectedIndex === 9}
            onClick={(): void => {
              onCloseDrawer()
              handleSelectItem(9)
              handleMenuChange(
                'certificationManager',
                menuExpanded === 'certificationManager'
              )
              setOpenRepoSubMenu(false)
              history.push(CERTIFICATION_MANAGER)
            }}
          >
            <ListItemIcon>
              <Tooltip title="Certification Manager">
                <ChromeReaderMode fontSize="small" />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Certification Manager" />
          </ListItemStyle>
        </Can>
      )}

      <ListItemStyle
        button
        selected={selectedIndex === 5}
        onClick={(): void => {
          onCloseDrawer()
          handleSelectItem(5)
          handleMenuChange('docPortal', menuExpanded === 'docPortal')
          setOpenRepoSubMenu(false)
          history.push(DOCUMENT_PORTAL)
        }}
      >
        <ListItemIcon>
          <Tooltip title="Document Portal">
            <InsertDriveFileIcon fontSize="small" />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Document Portal" />
      </ListItemStyle>
      {(ability.can(
        PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
        'any'
      ) ||
        ability.can(
          PermissionCodeAccess.ComplianceManagementSystem_Basic_RepositoryAccess,
          'any'
        )) && (
        <>
          <ListItemStyle
            button
            selected={selectedIndex === 6}
            onClick={(): void => {
              handleSubMenuClick()
              handleSelectItem(6)
            }}
          >
            <ListItemIcon>
              <Tooltip title="Document Repository">
                <FileCopyIcon fontSize="small" />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Document Repository" />
          </ListItemStyle>
          <Collapse in={openRepoSubMenu} timeout="auto" unmountOnExit>
            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemStyle
                button
                selected={selectedIndex === 7}
                onClick={(): void => {
                  onCloseDrawer()
                  handleSelectItem(7)
                  handleMenuChange('docRepo', menuExpanded === 'docRepo')
                  history.push(DOCUMENT_REPOSITORY)
                }}
              >
                <ListItemIcon>
                  <Tooltip title={isInternal ? 'Repository' : 'My Repository'}>
                    <AssignmentInd fontSize="small" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={isInternal ? 'Repository' : 'My Repository'}
                />
              </ListItemStyle>
              {!isInternal && (
                <ListItemStyle
                  button
                  selected={selectedIndex === 8}
                  onClick={(): void => {
                    onCloseDrawer()
                    handleSelectItem(8)
                    handleMenuChange(
                      'docThirdRepo',
                      menuExpanded === 'docThirdRepo'
                    )
                    history.push(THIRD_PARTY_REPOSITORY)
                  }}
                >
                  <ListItemIcon>
                    <Tooltip title="Third Party Repository">
                      <Assignment fontSize="small" />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary="Third Party Repository" />
                </ListItemStyle>
              )}
            </List>
          </Collapse>
        </>
      )}
      {userPermissions.controlPanelPermission && (
        <ListItemStyle
          open={opendedMenu}
          button
          selected={selectedIndex === 10}
          onClick={() => {
            handleSelectItem(10)
            onCloseDrawer()
            handleMenuChange('controlPanel', menuExpanded === 'controlPanel')
            window.open(process.env.REACT_APP_CONTROLPANEL_SITE, '_self')
          }}
        >
          <ListItemIcon>
            <Tooltip title="Control Panel" aria-label="controlPanel">
              <SettingsApplicationsOutlinedIcon fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Control Panel" />
        </ListItemStyle>
      )}

      {userPermissions.marketPlacePermission && (
        <ListItemStyle
          open={opendedMenu}
          button
          selected={selectedIndex === 11}
          onClick={() => {
            handleSelectItem(11)
            onCloseDrawer()
            handleMenuChange('marketPlace', menuExpanded === 'marketPlace')
            window.open(process.env.REACT_APP_MARKETPLACE_SITE, '_self')
          }}
        >
          <ListItemIcon>
            <Tooltip title="Marketplace" aria-label="marketPlace">
              <AccountBalanceWalletIcon fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Marketplace" />
        </ListItemStyle>
      )}
      {!process.env.REACT_APP_HIDE_FAQ_MENU && (
        <>
          <ListItemStyle
            button
            selected={selectedIndex === 12}
            onClick={(): void => {
              if (selectedIndex === 12) onCloseDrawer()
              handleSelectItem(12)
              handleMenuChange('faq', menuExpanded === 'faq')
            }}
          >
            <ListItemIcon>
              <Tooltip title="FAQ" aria-label="faq">
                <ForumIcon fontSize="small" />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItemStyle>
          <Collapse in={menuExpanded === 'faq'} timeout="auto" unmountOnExit>
            <ListSubMenu disablePadding>
              <ListItemNested
                open={opendedMenu}
                button
                onClick={() => {
                  onCloseDrawer()
                  window.open(KNOWLEDGE_BASE, '_blank')
                }}
              >
                <ListItemIcon>
                  <Tooltip title="Knowledge Base" aria-label="faq">
                    <MenuBookIcon fontSize="small" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText secondary="Knowledge Base" />
              </ListItemNested>
              <ListItemNested
                open={opendedMenu}
                button
                onClick={() => {
                  onCloseDrawer()
                  window.open(HELP_REQUEST, '_blank')
                }}
              >
                <ListItemIcon>
                  <Tooltip title="Help Request" aria-label="faq">
                    <HelpRoundedIcon fontSize="small" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText secondary="Help Request" />
              </ListItemNested>
            </ListSubMenu>
          </Collapse>
        </>
      )}
    </List>
  )
}

Menus.defaultProps = {
  opendedMenu: false,
}

export default Menus
